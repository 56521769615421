import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import '../styles/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone, faEnvelope, faHome, faParking, faWifi, faSnowflake, faSolarPanel,
} from '@fortawesome/free-solid-svg-icons';
import RoomAvailabilityCheck from './CheckAvailability';
import Weather from './Weather';
import Review from './Review';
import Footer from './Footer';
import Popup2 from './popup2'; // Import Popup2 component
import AvailableRoomsSection from './AvailableRoomsSection';
import Navbar from './Navbar/Navbar';
import logo1 from '../images/logo1.png';
import apartmentDamjan from '../images/26.jpg';
import studioJana from '../images/4.jpg';
import '../styles/fontStyles.css';
import '../styles/popup.css';
import '../styles/footer.css';
import '../styles/min_width_1440.css';
import '../styles/PinyonScript-Regular.ttf';

const Body = () => {
  const [availableRooms, setAvailableRooms] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const [openPopupId, setOpenPopupId] = useState(null);

  const handleAvailabilityChange = (availableRoomsData) => {
    setAvailableRooms(availableRoomsData);
    setShowContent(true);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (showContent) {
      const contentSection = document.getElementById('content-section');
      if (contentSection) {
        const yOffset = -80;
        const y = contentSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [showContent]);

  // Add the Android-specific text stroke effect here
  useEffect(() => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    if (isAndroid) {
      const headerTitle = document.querySelector('.header-title');
      if (headerTitle) {
        headerTitle.style.webkitTextStroke = '1px rgba(207, 197, 224, 1)';
      }
    }
  }, []);

  const handleViewMoreClick = (roomId) => {
    setOpenPopupId(roomId);
  };

  const handleClosePopup = () => {
    setOpenPopupId(null);
  };

  // Add the iPhone-specific text stroke effect here
  useEffect(() => {
    const isIPhone = /iPhone/i.test(navigator.userAgent);
    if (isIPhone) {
      const headerTitle = document.querySelector('.header-title');
      if (headerTitle) {
        headerTitle.style.webkitTextStroke = '0.3px rgba(207, 197, 224, 1)'; // Apply 1px stroke width for iPhone
      }
    }
  }, []);

  // Add the Linux-specific text stroke effect here
  useEffect(() => {
    const isLinux = /Linux/i.test(navigator.userAgent);
    if (isLinux) {
      const headerTitle = document.querySelector('.header-title');
      if (headerTitle) {
        headerTitle.style.webkitTextStroke = '1px rgba(207, 197, 224, 1)'; // Apply 1px stroke width for Linux
      }
    }
  }, []);

  // Add the Firefox-specific text stroke effect here
  useEffect(() => {
    const isFirefox = typeof InstallTrigger !== 'undefined'; // Firefox-specific check
    if (isFirefox) {
      const headerTitle = document.querySelector('.header-title');
      if (headerTitle) {
        headerTitle.style.color = 'transparent'; // Set text color to transparent
        headerTitle.style.webkitTextStroke = '1px rgba(207, 197, 224, 1)';
      }
    }
  }, []);

  // Add the Opera-specific text stroke effect here
  useEffect(() => {
    const isOpera = /OPR|Opera/.test(navigator.userAgent); // Check for Opera
    if (isOpera) {
      const headerTitle = document.querySelector('.header-title');
      if (headerTitle) {
        headerTitle.style.color = 'transparent'; // Set text color to transparent
        headerTitle.style.webkitTextStroke = '0.01px rgba(207, 197, 224, 1)';
      }
    }
  }, []);

  useEffect(() => {
    const isOperaGX = /OPR|Opera/.test(navigator.userAgent) && /GX/.test(navigator.userAgent); // Check for Opera GX
    if (isOperaGX) {
      document.body.classList.add('opera-gx'); // Add class to body
    }
  }, []);

  return (
    <>
      <Navbar />
      <header className="header">
        <span className="header-title">It isn&apos;t Perfect... It&apos;s Paradise!</span>
        <div className="signature">
          Asprovalta M Apartments
        </div>
        <div className="checkavaliliability">
          <RoomAvailabilityCheck
            renderInputs
            onAvailabilityChange={handleAvailabilityChange}
          />
        </div>
        <div className="weather">
          <Weather />
        </div>
      </header>
      {showContent && (
        <section className="content-section" id="content-section">
          <div className="room-availability">
            {availableRooms ? (
              <AvailableRoomsSection availableRooms={availableRooms} />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </section>
      )}

      {!showContent && (
        <section className="room-info">
          <div className="room-info-title-box">
            <img className="logos" src={logo1} alt="logos.png" />
            <div className="separator-room" />
            <div className="room-info-title">ACCOMMODATIONS</div>
          </div>
          <div className="room-photo-box">
            <div className="room-photo1">
              <img
                src={apartmentDamjan}
                alt="Apartment Damjan"
                className="photo1"
              />
              <button type="button" className="view-more" onClick={() => handleViewMoreClick(1)}>
                View More
              </button>
              <div className="photo-text1">Apartment Damjan</div>
            </div>
            <div className="room-photo2">
              <img
                src={studioJana}
                alt="Studio Jana"
                className="photo2"
              />
              <button type="button" className="view-more" onClick={() => handleViewMoreClick(2)}>
                View More
              </button>
              <div className="photo-text2">Studio Jana</div>
            </div>
          </div>
        </section>
      )}

      {/* Render Popup2 instead of Popup */}
      {openPopupId && (
        <Popup2
          roomName={openPopupId === 1 ? 'Apartment Damjan' : 'Studio Jana'}
          openPopupId={openPopupId}
          onClose={handleClosePopup}
          roomId={openPopupId}
        />
      )}
      <div className="reviews-title-box">
        <img className="logos" src={logo1} alt="logos.png" />
        <div className="separator-room" />
        <div className="reviews-title">REVIEWS</div>
      </div>
      <section className="review-section" id="review-section">
        <div className="review-background" />
        <div className="review-content">
          <Review />
        </div>
      </section>
      <div className="about-us-box">
        <div className="about-us">
          <div className="au-title">ABOUT US</div>
          <div className="separator-au" />
          <div className="about-us-text">Since 2017, Asprovalta M Apartments has been proudly family-owned, offering cozy and modern accommodations just steps from the beautiful shores of Greece. Located in the heart of Asprovalta, we welcome families, couples, and solo travelers to enjoy a relaxing and memorable stay. Our mission is to provide warm hospitality and personalized service, ensuring that every guest feels at home. Whether you&apos;re here to explore the local culture, soak up the sun by the beach, or discover nearby attractions, we&apos;re dedicated to making your experience unforgettable.</div>
        </div>
      </div>
      <section className="about-section" id="about-section">
        <div className="about-me">
          <img src={logo1} alt="Logo1" className="about-logo" />
          <p className="about-text">
            Asprovalta M Apartments is offering accommodation where you could
            relax and enjoy your holidays, feeling just like at home by the sea.
          </p>
        </div>
        <div className="tablet-box">
          <div className="rooms">
            <p className="contact">Rooms</p>
            <div className="family">
              <FontAwesomeIcon
                icon={faHome}
                style={{ color: '#faf1a0' }}
                aria-hidden="true"
              />
              <div className="room1">Apartman Damjan</div>
            </div>
            <div className="family">
              <FontAwesomeIcon
                icon={faHome}
                style={{ color: '#faf1a0' }}
                aria-hidden="true"
              />
              <div className="room1">Studio Jana</div>
            </div>
          </div>
          <div className="services">
            <p className="contact">Services</p>
            <div className="family">
              <FontAwesomeIcon
                icon={faParking}
                style={{ color: '#faf1a0' }}
                aria-hidden="true"
              />
              <div className="room1">Parking</div>
            </div>
            <div className="family">
              <FontAwesomeIcon icon={faSnowflake} style={{ color: '#faf1a0' }} aria-hidden="true" />
              <div className="room1">Air Conditioner</div>
            </div>
            <div className="family">
              <FontAwesomeIcon icon={faWifi} style={{ color: '#faf1a0' }} aria-hidden="true" />
              <div className="room1">Free WiFi</div>
            </div>
            <div className="family">
              <FontAwesomeIcon icon={faSolarPanel} style={{ color: '#faf1a0' }} aria-hidden="true" />
              <div className="room1">Solar Water Heater</div>
            </div>
          </div>
        </div>
        <div className="google-maps-widget">
          <p className="contact">Contact</p>
          <div className="phone-footer">
            <span className="phone-label" aria-hidden="true">
              <FontAwesomeIcon icon={faPhone} className="phone-icon" />
            </span>
            <a className="phone-footer" href="tel:+38971292496">
              +389 71 292 496
            </a>
          </div>
          <div className="email-footer">
            <span className="email-label-footer" aria-label="Email">
              <FontAwesomeIcon icon={faEnvelope} className="envelope-icon" />
            </span>
            <a className="email-footer" href="mailto:asprovaltam@yahoo.com">
              asprovaltam@yahoo.com
            </a>
          </div>
          <iframe
            title="Google Maps Widget"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.082988077087!2d23.7063886!3d40.7240395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a91f22438b3a3d%3A0xcee56071c2b751c8!2sAsprovalta%20M%20Apartments%2C%20Leof.%20Strimonikou%2C%20Asprovalta%2067003%2C%20Greece!5e0!3m2!1sen!2smk!4v1693671247227!5m2!1sen!2smk"
            width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div />
      </section>
      <div className="footer-container">
        <Footer />
      </div>
    </>
  );
};

export default Body;
