import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './settings.css';
import toast from 'react-hot-toast';
import ReactFlagsSelect from 'react-flags-select';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { fetchAndUpdateUser } from '../Sidebar/Sidebar';

const Settings = () => {
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({
    id: '',
    name: '',
    email: '',
    phone_number: '',
    country: '',
    photo: null,
  });
  const [editMode, setEditMode] = useState(false);
  const [removeAvatar, setRemoveAvatar] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const storedUserId = sessionStorage.getItem('userId');
    if (storedUserId) {
      setUserId(parseInt(storedUserId, 10));
    }
  }, []);

  useEffect(() => {
    if (userId) {
      const token = sessionStorage.getItem('token');
      axios
        .get(`https://asprovaltam.com/users/${userId}`, {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          console.log('Fetched user data:', response.data);
          setUser(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [userId]);

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (countryCode) => {
    setUser({ ...user, country: countryCode });
  };

  const handlePhotoChange = (e) => {
    const selectedPhoto = e.target.files[0];
    const validExtensions = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/webp',
    ];
    if (!validExtensions.includes(selectedPhoto.type)) {
      toast.error('Invalid file type. Please upload a JPG or PNG image.');
      return;
    }
    setUser({ ...user, photo: selectedPhoto });
  };

  const handleRemoveAvatarChange = (e) => {
    setRemoveAvatar(e.target.checked);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('user[name]', user.name);
    formData.append('user[email]', user.email);
    formData.append('user[phone_number]', user.phone_number);
    formData.append('user[country]', user.country);

    if (user.photo) {
      formData.append('user[avatar]', user.photo);
    }

    if (removeAvatar) {
      formData.append('remove_avatar', true);
    }

    const token = sessionStorage.getItem('token');
    axios
      .patch(`https://asprovaltam.com/users/${userId}`, formData, {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        // Check if the email was updated
        if (response.data.unconfirmed_email) {
          toast.success(
            'Profile updated successfully. Please check your email to confirm your new email address.',
          );
        } else {
          toast.success('Profile updated successfully');
        }

        setUser({ ...user, photo: null });
        setEditMode(false);
        fetchAndUpdateUser(setUser).then(() => {
          // Notify the Sidebar component to fetch the latest user data
          window.dispatchEvent(new Event('userUpdated'));
        });
      })
      .catch(() => {
        toast.error('Error updating profile');
      });
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleChangePassword = (event) => {
    event.preventDefault();

    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error('Please fill in all fields.');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('New password and confirmation do not match.');
      return;
    }

    const token = sessionStorage.getItem('token');
    axios
      .patch(
        `https://asprovaltam.com/users/${userId}/update_password`,
        {
          user: {
            current_password: oldPassword,
            password: newPassword,
            password_confirmation: confirmPassword,
          },
        },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        toast.success('Password updated successfully');
      })
      .catch(() => {
        toast.error('Error updating password');
      });
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <div className="content--header">
        <h2 className="header--title">Settings</h2>
      </div>
      <div className="entire-settings">
        <div className="profile-section">
          <div className="name_edit">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={user.name || ''}
              onChange={handleInputChange}
              disabled={!editMode}
            />
          </div>
          <div className="email_edit">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={user.email || ''}
              onChange={handleInputChange}
              disabled={!editMode}
            />
          </div>
          <div className="phone_edit">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              name="phone_number"
              value={user.phone_number || ''}
              onChange={handleInputChange}
              disabled={!editMode}
            />
          </div>
          <div className="country_edit">
            <label htmlFor="country">Country</label>
            <ReactFlagsSelect
              selected={user.country}
              onSelect={handleCountryChange}
              searchable
              disabled={!editMode}
            />
          </div>
          <div className="photo_edit">
            <label htmlFor="photo">Photo</label>
            <input
              type="file"
              id="photo"
              name="photo"
              accept=".jpg,.jpeg,.png,.gif,.webp"
              onChange={handlePhotoChange}
              disabled={!editMode}
            />
            {editMode && (
              <div className="add_change_avatar">
                <input
                  type="checkbox"
                  id="removeAvatar"
                  name="removeAvatar"
                  checked={removeAvatar}
                  onChange={handleRemoveAvatarChange}
                />
                <label htmlFor="removeAvatar">Add or Change Avatar Photo</label>
              </div>
            )}
          </div>
          <div className="save_edit">
            {editMode ? (
              <button
                className="save_edit_button"
                type="button"
                onClick={handleSubmit}
              >
                Save
              </button>
            ) : (
              <button
                className="save_edit_button"
                type="button"
                onClick={toggleEditMode}
              >
                Edit
              </button>
            )}
          </div>
        </div>
        <div className="change_password">
          <div className="form_change_pwd">
            <div className="sec_old_pwd">
              <label htmlFor="old_password">Old Password</label>
              <div className="password-wrapper-1">
                <input
                  type={showOldPassword ? 'text' : 'password'}
                  id="old_password"
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <IconButton
                  aria-label="toggle old password visibility"
                  onClick={toggleOldPasswordVisibility}
                  edge="end"
                  className="password-toggle-icon-1"
                >
                  {showOldPassword ? (
                    <Visibility
                      style={{ color: '#27374d', position: 'absolute' }}
                    />
                  ) : (
                    <VisibilityOff
                      style={{ color: '#27374d', position: 'absolute' }}
                    />
                  )}
                </IconButton>
              </div>
            </div>
            <div className="sec_new_pwd">
              <label htmlFor="new_password">New Password</label>
              <div className="password-wrapper-1">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  id="new_password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <IconButton
                  aria-label="toggle new password visibility"
                  onClick={toggleNewPasswordVisibility}
                  edge="end"
                  className="password-toggle-icon-1"
                >
                  {showNewPassword ? (
                    <Visibility
                      style={{ color: '#27374d', position: 'absolute' }}
                    />
                  ) : (
                    <VisibilityOff
                      style={{ color: '#27374d', position: 'absolute' }}
                    />
                  )}
                </IconButton>
              </div>
            </div>
            <div className="sec_conf_pwd">
              <label htmlFor="confirm_password">Confirm Password</label>
              <div className="password-wrapper-1">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirm_password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <IconButton
                  aria-label="toggle confirm passwordvisibility"
                  onClick={toggleConfirmPasswordVisibility}
                  edge="end"
                  className="password-toggle-icon-1"
                >
                  {showConfirmPassword ? (
                    <Visibility
                      style={{ color: '#27374d', position: 'absolute' }}
                    />
                  ) : (
                    <VisibilityOff
                      style={{ color: '#27374d', position: 'absolute' }}
                    />
                  )}
                </IconButton>
              </div>
            </div>
            <button
              className="save_pwd"
              type="submit"
              onClick={handleChangePassword}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
