import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
/* import { useNavigate } from 'react-router-dom'; */
/* import toast from 'react-hot-toast'; */
import toast from 'react-hot-toast';
import RoomImages from './RoomImages';
import RoomAmenities from './RoomAmenities';

const Popup = ({
  roomName, openPopupId, onClose,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  /* const navigate = useNavigate(); */
  const sliderRef = useRef(null);
  const popupRef = useRef(null);

  const isOpen = openPopupId !== null && openPopupId !== undefined;

  let roomInfo;
  if (roomName in RoomImages) {
    roomInfo = RoomImages[roomName];
  } else {
    roomInfo = RoomImages.Other;
  }

  const { images } = roomInfo;

  const closePopup = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCheckAvailabilityClick = () => {
    closePopup();
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll animation
    });
    toast('Please select both a start date and an end date before checking availability.', {
      icon: 'ℹ️', // Optional: add an icon for clarity
      style: {
        background: '#f0f8ff',
        color: '#333',
        border: '3px solid #007bff',
        position: 'relative',
        top: '70px',
      },
      duration: 5000, // Display duration
    });
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentImage);
    }
  }, [currentImage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.body.style.overflow = 'hidden';

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [closePopup]);

  if (!isOpen) {
    return null;
  }

  const PrevArrow = ({ onClick }) => (
    <button type="button" className="slick-arrow slick-prev" onClick={onClick} aria-label="Previous">
      {/* Optionally, add an icon or visual cue here */}
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button type="button" className="slick-arrow slick-next" onClick={onClick} aria-label="Next">
      {/* Optionally, add an icon or visual cue here */}
    </button>
  );

  PrevArrow.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  NextArrow.propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    initialSlide: currentImage,
  };

  const handleImageHover = (index) => {
    setCurrentImage(index);
  };

  return ReactDOM.createPortal(
    <div className="popup" ref={popupRef}>
      <div className="popup-inner">
        <div className="room_close">
          <h2>{roomName}</h2>
          <div className="btn">
            <button type="button" className="close_btn" onClick={closePopup}>
              X
            </button>
          </div>
        </div>
        <div className="popup-content">
          <div className="slider_other_image">
            <div className="slider-my-container">
              <Slider
                ref={sliderRef}
                infinite={settings.infinite}
                slidesToShow={settings.slidesToShow}
                slidesToScroll={settings.slidesToScroll}
                centerMode={settings.centerMode}
                centerPadding={settings.centerPadding}
                prevArrow={settings.prevArrow}
                nextArrow={settings.nextArrow}
                initialSlide={settings.initialSlide}
              >
                {images.map((image) => (
                  <div className="slider-container" key={image.src}>
                    <img
                      src={image.src}
                      alt={roomName}
                      className="slider-image"
                      style={{
                        maxWidth: 430,
                        maxHeight: 350,
                        width: 'auto',
                        height: 'auto',
                        margin: 'auto',
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="other-images">
              {images.slice(1).map((image, index) => (
                <img
                  key={image.src}
                  src={image.src}
                  alt={roomName}
                  className="other-image"
                  onMouseEnter={() => handleImageHover(index + 1)}
                />
              ))}
            </div>
          </div>
          <div className="room-description">
            <RoomAmenities roomName={roomName} />
          </div>
        </div>
      </div>
      <div className="popup-footer">
        <button type="button" className="reserve-btn" onClick={handleCheckAvailabilityClick}>
          CHECK AVAILABILITY
        </button>
      </div>
    </div>,
    document.body,
  );
};

Popup.defaultProps = {
  openPopupId: null,
};

Popup.propTypes = {
  roomName: PropTypes.string.isRequired,
  openPopupId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default Popup;
